@import './app.colours.scss';

.primary-input {
    color: $secondary-seven;
    border-width: 1px;
    border-style: solid;
    border-color: $secondary-eight;
    border-radius: 4px;
    box-shadow: 0 4px 8px $secondary-six;
    padding-left: 8px;
    padding-right: 8px;
}

.primary-text-area {
    color: $secondary-seven;
    border-width: 1px;
    border-style: solid;
    border-color: $secondary-eight;
    border-radius: 4px;
    box-shadow: 0 4px 8px $secondary-six;
    padding-left: 8px;
    padding-right: 8px;
}

.primary-input-container {
    border-style: solid;
    border-width: 1px;
    border-color: $secondary-two;
    border-radius: 8px;
    padding: 10px;
    text-align: left;

    .primary-input-label {
        width: 100%;
        color: $secondary-one;
        display: block;
        text-align: left;
        font-weight: 600;
    }

    .primary-input {
        border: none;
        outline: none;
        color: $secondary-one;
        box-shadow: none;
    }

    .primary-text-area {
        border-width: 2px;
        border-color: $secondary-one;
        outline: none;
        color: $primary-one;
    }
}

.primary-input-container:focus-within {
    .primary-input-label {
        color: $secondary-four;
    }

    .primary-input {
        border-width: 2px;
        border-color: $secondary-one;
        outline: none;
        color: $secondary-one;
    }

    .primary-text-area {
        border-width: 2px;
        border-color: $primary-one;
        outline: none;
        color: $secondary-one;
    }
}

.primary-select {
    color: $secondary-seven;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 4px 8px $secondary-six;

    .ant-select-selection-placeholder {
        color: $secondary-ten;
    }

    .ant-select-arrow {
        color: $black;
    }
}

.primary-select:focus-within {
    border-width: 2px;
    border-color: $primary-one;
    outline: none;
    color: $primary-one;
}

.ant-switch-checked {
    background: $tertiary-four;
    outline: $tertiary-four;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: $white !important;
    border: none;
}

.secondary-input-container {
    border: none;
    padding: 10px;
    text-align: left;

    .secondary-input-label {
        width: 100%;
        color: $secondary-one;
        display: block;
        text-align: left;
        font-weight: 600;
    }

    .secondary-input {
        border-style: solid;
        border-width: 1px;
        border-color: $secondary-four;
        outline: none;
        color: $secondary-one;
        box-shadow: none;
        border-radius: 8px;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .secondary-text-area {
        border-width: 1px;
        border-color: $secondary-four;
        outline: none;
        color: $secondary-one;
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
}

.secondary-input-container:focus-within {
    .secondary-input-label {
        color: $secondary-four;
    }

    .secondary-input {
        border-style: solid;
        border-width: 1px;
        border-color: $secondary-four;
        outline: none;
        color: $secondary-one;
        box-shadow: none;
        border-radius: 8px;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .secondary-text-area {
        border-width: 1px;
        border-color: $secondary-four;
        outline: none;
        color: $secondary-one;
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
}

.secondary-select {
    color: $secondary-one;
    border-width: 1px;
    border-style: solid;
    border-color: $secondary-four;
    border-radius: 8px;
    height: 40px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: $white;
    box-shadow: none;

    .ant-select-selection-placeholder {
        color: $secondary-ten;
    }

    .ant-select-arrow {
        color: $black;
    }
}

.secondary-select:focus-within {
    border-width: 1px;
    border-color: $secondary-four;
    outline: none;
    color: $secondary-one;
}