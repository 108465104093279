:root {
    --desktop-header-height: 100px;
    --desktop-breakpoint: 992px;
    --large-desktop-breakpoint: 1200px;
    --breakpoint-one: 1050px;
    --breakpoint-two: 990px;
}

$desktop-header-height: 100px;
$desktop-breakpoint: 992px;
$large-desktop-breakpoint: 1200px;
$breakpoint-one: 1300px;
$breakpoint-two: 1086px;
$breakpoint-three: 982px;

/* -------------- Fonts -------------- */

.text-size-one {
    font-size: 9px;
}

.text-size-two {
    font-size: 10px;
}

.text-size-three {
    font-size: 11px;
}

.text-size-four {
    font-size: 12px;
}

.text-size-five {
    font-size: 13px;
}

.text-size-six {
    font-size: 14px;
}

.text-size-seven {
    font-size: 16px;
}

.heading-one {
    font-size: 20px;
    font-weight: bold;
}

.heading-two {
    font-size: 22px;
    font-weight: bold;
}

.heading-three {
    font-size: 24px;
    font-weight: bold;
}

.heading-four {
    font-size: 30px;
    font-weight: bold;
}

.heading-five {
    font-size: 32px;
    font-weight: bold;
}

.heading-six {
    font-size: 34px;
    font-weight: bold;
}

.heading-seven {
    font-size: 36px;
    font-weight: bold;
}

.heading-eight {
    font-size: 40px;
    font-weight: bold;
}

.heading-nine {
    font-size: 42px;
    font-weight: bold;
}

.heading-ten {
    font-size: 44px;
    font-weight: bold;
}

.heading-eleven {
    font-size: 46px;
    font-weight: bold;
}

.heading-twelve {
    font-size: 50px;
    font-weight: bold;
}

.heading-thirteen {
    font-size: 52px;
    font-weight: bold;
}

.heading-fourteen {
    font-size: 54px;
    font-weight: bold;
}

/* -------------- Fonts -------------- */

.responsive-heading-one {
    font-size: 20px;
    font-weight: 600;
}

.responsive-heading-two {
    font-size: 26px;
    font-weight: 600;
}

.responsive-text-one {
    font-size: 16px;
}

.responsive-text-two {
    font-size: 14px;
}

.responsive-icon-one {
    font-size: 20px;
}


.responsive-text-icon-container {
    .responsive-icon-one {
        font-size: 20px;
    }

    .responsive-text-one {
        font-size: 16px;
        position: relative;
        top: -5px;
    }
}

.responsive-button-container-one {
    width: 100%;
    text-align: center;

    .primary-button {
        width: 400px;
        margin: auto;
        font-size: 14px;
        height: 45px;
    }
}

@media (max-width: 1024px) {
    .responsive-heading-one {
        font-size: 18px;
    }

    .responsive-text-one {
        font-size: 16px;
    }

    .responsive-icon-one {
        font-size: 18px;
    }

    .responsive-text-icon-container {
        .responsive-icon-one {
            font-size: 18px;
        }
    
        .responsive-text-one {
            font-size: 16px;
            position: relative;
            top: -2px;
        }
    }
}

@media (max-width: 796px) {
    .responsive-heading-one {
        font-size: 22px;
    }

    .responsive-heading-two {
        font-size: 20px;
    }

    .responsive-text-one {
        font-size: 14px;
    }

    .responsive-text-two {
        font-size: 12px;
    }

    .responsive-icon-one {
        font-size: 16px;
    }

    .responsive-text-icon-container {
        .responsive-icon-one {
            font-size: 16px;
        }
    
        .responsive-text-one {
            font-size: 14px;
            position: relative;
            top: -0;
        }
    }

}

@media (max-width: 550px) {
    .responsive-heading-one {
        font-size: 3.5vw;
    }

    .responsive-heading-two {
        font-size: 4.5vw;
    }

    .responsive-text-one {
        font-size: 2.5vw;
    }

    .responsive-icon-one {
        font-size: 3vw;
    }

    .responsive-text-two {
        font-size: 3.5vw;
    }

    .responsive-text-icon-container {
        .responsive-icon-one {
            font-size: 3vw;
        }
    
        .responsive-text-one {
            font-size: 2.5vw;
            position: relative;
            top: -0;
        }
    }

    .responsive-button-container-one {
        width: 100%;
        text-align: center;

        .primary-button {
            width: 70%;
            margin: auto;
            font-size: 2.5vw;
            height: 7vw;
        }
    }
}

@media (max-width: 420px) {
    .responsive-button-container-one {
        width: 100%;
        text-align: center;

        .primary-button {
            width: 90%;
            margin: auto;
            font-size: 3.5vw;
            height: 12vw;
            min-width: none;
        }
    }
}

@media (max-width: 375px) {
    
}

@media (max-width: 320px) {
    
}