$white: #fff;
$black: #000;
$primary-one: #c5effd;
$primary-two: #bd2031;
$primary-three: #bfe4ff;
$primary-four: #eaf6ff;
$primary-five: #ecf6fe;
$primary-six: #4e67a0;
$primary-seven: #afc7ff;
$secondary-one: #444445;
$secondary-two: #f5f5f5;
$secondary-three: #3d3d3d;
$secondary-four: #707070;
$secondary-five: #d9d9d9;
$secondary-six: #f1f1f1;
$secondary-seven: #d6d6d6;
$secondary-eight: #fffafa;
$secondary-nine: #e1e1e3;
$secondary-ten: #757575;
$secondary-eleven: #dedede;
$secondary-twelve: #dfdfdf;
$secondary-thirteen: #9e9e9e;
$tertiary-one: #444445;
$tertiary-two: #bf001253;
$tertiary-three: #fae3df;
$tertiary-four: #00b11b;
$tertiary-five: #d5f7e6;
$tertiary-six: #f8be32;
$tertuary-seven: #f00;
$app-background: #fff;
$background-one: #f5f6f8;
$background-two: #f0f2f5;
