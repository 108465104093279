@import './app.colours.scss';

.ant-pagination {
    text-align: right;
    padding-right: 8vw;
    margin-bottom: 50px;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
    color: $primary-two;
}

.ant-pagination-item-active {
    font-weight: 800;
    background: transparent;
    border: none;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
    color: $primary-one;
    border: none;
}

.ant-pagination-next button {
    width: 50px;
}

.ant-pagination-prev button {
    width: 50px;
}
