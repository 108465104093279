@import './app.colours.scss';

.transparent-button {
    background: transparent;
    color: $white;
    border: none;
}

.primary-button {
    background-color: $primary-two;
    color: $white;
    box-shadow: 0 0 4px $primary-two;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.primary-button:hover {
    background-color: $primary-two;
    color: $white;
    box-shadow: 0 0 4px $primary-two;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.primary-button:focus {
    background-color: $primary-two;
    color: $white;
    box-shadow: 0 0 4px $primary-two;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.secondary-button {
    background-color: transparent;
    color: $secondary-one;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.secondary-button:hover {
    background-color: transparent;
    color: $secondary-one;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.secondary-button:focus {
    background-color: transparent;
    color: $secondary-one;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.tertiary-button {
    background-color: $tertiary-one;
    box-shadow: 0 0 4px $black;
    color: $white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.tertiary-button:hover {
    background-color: $tertiary-one;
    box-shadow: 0 0 4px $black;
    color: $white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.tertiary-button:focus {
    background-color: $tertiary-one;
    box-shadow: 0 0 4px $black;
    color: $white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quaternary-button {
    background-color: transparent;
    color: $secondary-one;
    box-shadow: none;
    border-style: solid;
    border-width: 1px;
    border-color: $secondary-seven;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quaternary-button:hover {
    background-color: transparent;
    color: $secondary-one;
    border-style: solid;
    border-width: 1px;
    border-color: $secondary-seven;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quaternary-button:focus {
    background-color: transparent;
    color: $secondary-one;
    border-style: solid;
    border-width: 1px;
    border-color: $secondary-seven;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quinary-button {
    background-color: transparent;
    color: $secondary-seven;
    box-shadow: none;
    border-style: solid;
    border-width: 2px;
    border-color: $secondary-seven;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quinary-button:hover {
    background-color: transparent;
    color: $secondary-seven;
    border-style: solid;
    border-width: 2px;
    border-color: $secondary-seven;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.quinary-button:focus {
    background-color: transparent;
    color: $secondary-seven;
    border-style: solid;
    border-width: 2px;
    border-color: $secondary-seven;
    border-radius: 9px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.senary-button {
    background-color: $tertiary-six;
    color: $white;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.senary-button:hover {
    background-color: $tertiary-six;
    color: $white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.senary-button:focus {
    background-color: $tertiary-six;
    color: $white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 1.5px;
}
