/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import '../src/assets/styling/app.buttons.scss';
@import '../src/assets/styling/app.colours.scss';
@import '../src/assets/styling/app.input.scss';
@import '../src/assets/styling/app.labels.scss';
@import '../src/assets/styling/app.sizes.scss';
@import '../src/assets/styling/app.table.scss';

.content-container {
    background-color: $app-background;
    padding-left: 11vw;
    padding-right: 11vw;
}

.admin-content-container {
    padding-left: 2.5vw;
    padding-right: 8vw;
}

.website-content-container {
    padding-left: 15vw;
    padding-right: 15vw;
}

.blue-back-container {
    background-color: $primary-one;
}

@media only screen and (max-width: 1300px) {
    .content-container {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

@media only screen and (max-width: 1087px) {
    .content-container {
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

@media only screen and (max-width: 982px) {
    .content-container {
        padding-left: 1vw;
        padding-right: 1vw;
    }
}

@media (max-width: 796px) {
    .website-content-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.highcharts-series rect {
    background-color: #a6d7fd;
    fill: #a6d7fd;
}

.ant-upload.ant-upload-drag {
    display: table;
    background-color: #fff;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    background: #fff !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 18px;
    color: $primary-one;
    font-weight: bold;
}

.ant-spin-dot-item {
    background-color: $primary-two;
}

.ant-spin-text {
    color: $black;
    font-weight: 600;
}